"use client";
import { ProductSummary } from "@/types/Navigation";
import CTALink from "@/components/Navigation/LearnContent/ctaLink";
import Description from "./ui/Description";
import Links from "./ui/Links";
import Features from "./ui/Features";
type Props = {
  productResources?: ProductSummary[] | null;
};

export default function ProductResources({ productResources }: Props) {
  if (!productResources || productResources.length === 0) return null;
  return (
    <div className="flex flex-wrap">
      {productResources.map((product) => (
        <div
          key={product.title}
          className="mb-12 w-full relative px-4 grow-0 shrink-0 basis-1/2 max-w-[50%] lg:basis-1/3 lg:max-w-[33.33333333%]"
        >
          <div className="mt-0 mb-2 text-base text-primary font-bold">
            {product.title}
          </div>
          <Description description={product.description} />
          <Features features={product.features} />
          <Links
            links={product.links}
            html_id="guide-link"
            element_type="entry"
            location="nav-dropdown"
          />
          {product?.cta?.label && product?.cta?.url && (
            <CTALink
              cta={{
                ...product?.cta,
                html_id: "guide-link",
                location: "nav-dropdown",
                element_type: "entry",
              }}
              className="my-4"
            />
          )}
        </div>
      ))}
    </div>
  );
}
