"use client";
import { Hero as HeroType } from "@/types/Navigation";
import CTALink from "@/components/CTA/ctaLink";

import { ElementType } from "@/constants/tagular/main";
import { getHtmlIdNavHero, hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  hero?: HeroType | null;
  location?: string;
};

export default function Hero({ hero, location }: Props) {
  const viewAllUrl = hero?.cta?.viewAll?.url || "/";
  const learnMoreUrl = hero?.cta?.learnMore?.url || "/";

  if (!hero) return null;

  return (
    <div className="bg-primary text-white p-8">
      <div className="flex items-center justify-between w-full gap-4">
        <div className="m-0 grow mb-2 text-[2em]/9 font-bold">{hero.title}</div>
      </div>
      <div className="w-auto max-w-[520px] text-white text-sm mb-4">
        {hero.subTitle}
      </div>
      <div className="flex flex-wrap gap-2 flex-col md:flex-row">
        {hero?.cta?.viewAll && (
          <CTALink
            type="brand"
            label={hero.cta.viewAll.label}
            url={viewAllUrl}
            location={location}
            html_id={getHtmlIdNavHero(hero.title, "primary")}
            name={hyphenateForTagular(hero.cta.viewAll.label)}
            element_type={ElementType.Entry}
          />
        )}
        {hero?.cta?.learnMore && (
          <CTALink
            type="brandOutline"
            label={hero.cta.learnMore.label}
            url={learnMoreUrl}
            location={location}
            html_id={getHtmlIdNavHero(hero.title, "secondary")}
            name={hyphenateForTagular(hero.cta.learnMore.label)}
            element_type={ElementType.Entry}
          />
        )}
      </div>
    </div>
  );
}
