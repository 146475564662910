import { LinkList } from "@/types/Navigation";
import { cn } from "@/lib/utils";
import { hyphenateForTagular } from "@/utils/tagular/helpers";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
  CommonVariants,
} from "@/constants/tagular/main";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import useElementEvents from "@/hooks/eventing/useElementEvents";

type LinkListsProps = {
  linkLists?: LinkList[] | null;
};

type GenerateLinkProps = {
  title: string;
  url: string;
  index: number;
  additionalClasses?: string;
};

const GenerateLink = ({
  title,
  url,
  index,
  additionalClasses,
}: GenerateLinkProps) => {
  const webElement: ElementViewed["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: "topic-link",
    location: "nav-dropdown",
    position: index.toString(),
    text: title,
    name: hyphenateForTagular(title),
  };

  const { elementClicked } = useElementEvents({ webElement });

  return (
    <a
      onClick={() => elementClicked({ outboundUrl: url })}
      href={url}
      className={cn`text-link text-sm hover:underline hover:text-link-hover ${additionalClasses})`}
    >
      {title}
    </a>
  );
};

export default function LinkLists({ linkLists }: LinkListsProps) {
  if (!linkLists || linkLists.length === 0) return null;
  return (
    <div className="flex flex-wrap">
      {linkLists.map((list, listIndex) => (
        <div
          key={list.title}
          className="max-w-1/3 flex flex-col basis-2/6 mb-12 px-4"
        >
          {/* Parent link */}
          <div className="mt-0 mb-2">
            <GenerateLink
              title={list.title || ""}
              url={list.url || ""}
              index={listIndex + 1}
              additionalClasses="text-base font-bold"
            />
          </div>
          {/* Child link */}
          <ul>
            {list.links.map((link, itemIndex) => {
              return (
                <li key={itemIndex}>
                  <GenerateLink
                    title={link.label}
                    url={link.url}
                    index={Number(`${listIndex + 1}.${itemIndex + 1}`)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
}
