import { CTA } from "@/types";
import CTALink from "@/components/Navigation/LearnContent/ctaLink";

type Props = {
  links?: CTA[] | null;
  element_type?: string;
  location?: string;
  html_id?: string;
  name?: string;
  class_name?: string;
  variant?: string;
};

export default function Links({
  links,
  element_type,
  location,
  html_id,
  variant,
}: Props) {
  if (!links || links.length === 0) return null;

  return (
    <ul>
      {links.map((link, i) => {
        return (
          <li key={i}>
            <CTALink
              cta={{
                url: link.url,
                label: link.label,
                html_id,
                location,
                element_type,
                position: (i + 1).toString(),
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}
