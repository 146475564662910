import getEnv from "@/utils/getEnv";
import { Pathnames, DomainsConfig } from "next-intl/routing";

const locales = ["en", "es"] as const;
const defaultLocale = "en";
const localePrefix = "as-needed";
const localeCookie = false;
const localeDetection = false; // cloudflare caches the locale detection-based redirect for everyone - no bueno

const domainUrl = new URL(getEnv("NEXT_PUBLIC_SITE_URL", "www.edx.org"));
const domain = domainUrl.hostname;

const pathnames = {
  "/": "/",
  // Example of adding language specific routes
  // '/about': {
  //   en: '/about',
  //   es: '/acerca'
  // }
} satisfies Pathnames<typeof locales>;

const domains = [
  {
    domain,
    defaultLocale: "en",
    locales: ["en", "es"],
  },
] satisfies DomainsConfig<typeof locales>;

export {
  locales,
  defaultLocale,
  localePrefix,
  localeCookie,
  localeDetection,
  pathnames,
  domains,
};
